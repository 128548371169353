//import React, { useState } from "react";
import { Helmet } from "react-helmet-async";

export default function About ({ title }) {
  return [
    <div className="page about">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="page-content">
        <h1>Эта страница в разработке.</h1>
      </div>
    </div>
  ]
}