import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles/main/footer.scss';
import './styles/home/home.scss';
//import './styles/about/about.scss';
import './styles/main/logo.scss';
import './styles/main/style.scss';
import './styles/navbars/base.scss';
import './styles/navbars/navbar.scss';
import './styles/navbars/sidebar.scss';
import './styles/rules/rules.scss';
import './styles/scroll/scroll.scss';
import './styles/scroll/scrollbar.scss';
import './styles/subscription/subscription.scss';
//import './styles/scroll/scroll-target.scss';
//import './styles/main/notfoundpage.scss';
//import './styles/auth/login.scss';
import './styles/auth/log.scss';
import './styles/buttons/buttons.scss';
import './styles/pages/pages.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode><App/></React.StrictMode>
);
