//import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";

export default function Notfound ({ title }) {
  return [
    <div className="page notfound">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="page-content">
        <h1>Страницы на которую вы попытались перейти не существует.</h1>
        <NavLink className="button green" to={`/`}>Вернуться на главную</NavLink>
      </div>
    </div>
  ]
}