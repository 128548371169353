import React, { useState, useEffect } from "react";
import basisPath from "../../../storage/rulesMD/Chapters/1.Common_Principles.md";
import ReactMarkdown from 'react-markdown';
import { Helmet } from "react-helmet-async";

export default function Basis ({ title }) {
  useEffect(() => {
    fetch(basisPath).then(res => res.text()).then(txt => { setBasis(txt) })
  })
  const [basis, setBasis] = useState("")
  return (
    <div id="basis" className="scroll-target">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ReactMarkdown>{basis || "Loading..."}</ReactMarkdown>
    </div>
  )
}