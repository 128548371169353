import React, { useState, useEffect } from "react";
import policyPath from "../../../storage/rulesMD/Chapters/4.Politics.md";
import ReactMarkdown from 'react-markdown';
import { Helmet } from "react-helmet-async";

export default function Policy ({ title }) {
  const [policy, setPolicy] = useState("")
  useEffect(() => {
    fetch(policyPath).then(res => res.text()).then(txt => { setPolicy(txt) })
  })
  return (
    <div id="policy" className="scroll-target">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ReactMarkdown>{policy || "Loading..."}</ReactMarkdown>
    </div>
  )
}