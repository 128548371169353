import { NavLink } from "react-router-dom";

export default function Sidebar () {
  return (
    <div className="sidebar navbar-content">
      <input type="checkbox" id="menu-toggle" className="checkbox-r"/>
      <div className="label-container">
        <label className="menu-toggle" for="menu-toggle">
          <div className="hamburger-line"></div>
        </label>
      </div>
      <nav className="side-items menu-items">
        <li><NavLink to={`/rules/basis`}>Основные принципы</NavLink></li>
        <li><NavLink to={`/rules/roles`}>Роли</NavLink></li>
        <li><NavLink to={`/rules/spawn`}>Спавн</NavLink></li>
        <li><NavLink to={`/rules/policy`}>Политика</NavLink></li>
        <li><NavLink to={`/rules/wars`}>Войны</NavLink></li>
        <li><NavLink to={`/rules/civil`}>Гражданские войны</NavLink></li>
        <li><NavLink to={`/rules/administration`}>Администрация</NavLink></li>
        <li><NavLink to={`/rules/subscription`}>Подписка</NavLink></li>
      </nav>
    </div>
  )
}