import { React } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Navbar from "./components/layout/Navbar.jsx";
import Footer from "./components/layout/Footer.jsx";
import Home from "./components/pages/Home.jsx";
import About from "./components/pages/About.jsx";
import Rules from "./components/pages/Rules/Rules.jsx";
import Subscription from "./components/pages/Subscription.jsx";
import Notfound from "./components/pages/Notfound.jsx";
import Me from "./components/pages/Me.jsx";
import RequireAuth from "./components/hoc/RequireAuth.jsx";
import { AuthProvider } from "./components/hoc/AuthPrivider.jsx";
import getScroll from "./scripts/functions/getScroll.ts";

function App() {
  getScroll();
  const routes = [
    {
      path: "/", element: <Home title="DiPix - Главная"/>
    }, {
      path: "/rules/*", element: <Rules title="DiPix - Правила"/>
    }, {
      path: "/about", element: <About title="DiPix - О сервере"/>
    }, {
      path: "/subscription", element: <RequireAuth>
        <Subscription/>
      </RequireAuth>
    }, {
      path: "/me", element: <Me title="DiPix - Аккаунт"/>
    }, {
      path: "/*", element: <Notfound title="DiPix - Ошибка"/>
    }
  ]
  return (
    <AuthProvider>
      <HelmetProvider>
        <div className="App">
          <BrowserRouter>
            <Navbar/>
            <Routes>
              {routes.map((e, k) => (
                <Route key={k} path={e.path} element={e.element} />
              ))}
            </Routes>
            <Footer/>
          </BrowserRouter>
        </div>
      </HelmetProvider>
    </AuthProvider>

  );
}
export default App;
