import React, { useState, useEffect } from "react";
import spawnPath from "../../../storage/rulesMD/Chapters/3.Spawn.md";
import ReactMarkdown from 'react-markdown';
import { Helmet } from "react-helmet-async";

export default function Spawn ({ title }) {
  const [spawn, setSpawn] = useState("")
  useEffect(() => {
    fetch(spawnPath).then(res => res.text()).then(txt => { setSpawn(txt) })
  })
  return (
    <div id="spawn" className="scroll-target">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ReactMarkdown>{spawn || "Loading..."}</ReactMarkdown>
    </div>
  )
}