import React, { useState, useEffect } from "react";
import rolesPath from "../../../storage/rulesMD/Chapters/2.Roles.md";
import ReactMarkdown from 'react-markdown';
import { Helmet } from "react-helmet-async";

export default function Roles ({ title }) {
  const [roles, setRoles] = useState("")
  useEffect(() => {
    fetch(rolesPath).then(res => res.text()).then(txt => { setRoles(txt) })
  })
  return (
    <div id="roles" className="scroll-target">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ReactMarkdown>{roles || "Loading..."}</ReactMarkdown>
    </div>
  )
}