import { useLocation, Navigate } from 'react-router-dom';
import { useAuth } from '../../scripts/hook/useAuth.ts';

export default function RequireAuth ({ children }) {
  const location = useLocation()
  const {user} = useAuth();
  if (!user) {
    return <Navigate to={`/me`} state={{ from: location }}/>
  }
  return children
}