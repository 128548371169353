import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../scripts/hook/useAuth.ts';

export default function Me ({ title }) {
  const navigate = useNavigate();
  const location = useLocation();
  const {signIn, user} = useAuth();
  const fromPage = location.state?.from?.pathname || '/'
  
  const urlParams = new URLSearchParams(window.location.search);
  const codeFromUrl = urlParams.get('code');
  if (codeFromUrl) {
    signIn(codeFromUrl, () => navigate(fromPage, { replace: true }));
  }
  const discordOAuthLink = `https://discord.com/api/oauth2/authorize?client_id=886518978908917780&redirect_uri=https%3A%2F%2Fwww.dipix.pw%2Fme&response_type=code&scope=identify`;

  const loginText = user ? 'Вы успешно авторизировались' : 'Авторизация через Discord';
  const linkDisplayStyle = { display: user ? 'none' : 'true' };
  return (
    <div className='page me'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="page-content">
        <h1>{loginText}</h1>
        <a className="button blue" href={discordOAuthLink} style={linkDisplayStyle}>Авторизоваться</a>
      </div>
    </div>
  )
}
