//import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export default function Home ({ title }) {
  //navigator.clipboard.writeText("play.dipix.pw");
  return (
    <div className="page home">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="page-content">
        <h1>Политический сервер для всех</h1>
        <div className="home-navbar">
          <NavLink className="button red" to={`/subscription`}>Приобрести доступ</NavLink>
          <a className="button blue" href="https://discord.gg/7w3t5VhKHS" target="blank">Discord</a>
        </div>
        <p>1.20.1/1.20.10 · Minecraft: Java/Bedrock Edition · Лицензия не обязательна</p>
      </div>
    </div>
  )
}
