import { createContext, useState } from 'react';
import getAuth from '../../scripts/functions/getAuth.ts';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  // Попробуем получить пользователя из localStorage при загрузке страницы
  const savedUser = JSON.parse(localStorage.getItem('user'));
  const [user, setUser] = useState(savedUser);

  const signIn = (codeFromUrl, cb) => {
    getAuth(codeFromUrl).then(function(result) {
      setUser(result);
      localStorage.setItem('user', JSON.stringify(result));
      cb();
    });
  };
  const signOut = (cb) => {
    setUser(null);
    localStorage.removeItem('user');
    cb();
  };
  const value = { user, signIn, signOut }; 
  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};



