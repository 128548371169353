import axios from 'axios';
//import dotenv from  'dotenv';

export default function getAuth (codeFromUrl: string) {
  console.log(process.env.REACT_APP_REDIRECT_URL)
  async function getAccessToken () {
    const params = new URLSearchParams();
    params.append('client_id', process.env.REACT_APP_CLIENT_ID || '')
    params.append('client_secret', process.env.REACT_APP_CLIENT_SECRET || '')
    params.append('grant_type', 'authorization_code')
    params.append('code', codeFromUrl)
    params.append('redirect_uri', process.env.REACT_APP_REDIRECT_URI || '')
    params.append('scope', 'identify')
    const response = await axios.post('https://discord.com/api/oauth2/token', params);
    return response.data.access_token;
  }
  // Функция для получения информации о пользователе
  async function getUserInfo () {
    const headers = { Authorization: `Bearer ${await getAccessToken()}` };
    const response = await axios.get('https://discord.com/api/users/@me', { headers });
    return response.data;
  }
  return getUserInfo();
};