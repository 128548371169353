import React, { useState, useEffect } from "react";
import warsPath from "../../../storage/rulesMD/Chapters/5.Wars.md";
import ReactMarkdown from 'react-markdown';
import { Helmet } from "react-helmet-async";

export default function Wars ({ title }) {
  const [wars, setWars] = useState("")
  useEffect(() => {
    fetch(warsPath).then(res => res.text()).then(txt => { setWars(txt) })
  })
  return (
    <div id="wars" className="scroll-target">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ReactMarkdown>{wars || "Loading..."}</ReactMarkdown>
    </div>
  )
}