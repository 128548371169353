//import React, { useState } from "react";
import { Icon } from '@iconify/react';
import { NavLink } from 'react-router-dom';

export default function Footer () {
  return (
    <footer>
      <div className="get-help">
        <h4>Нужна помощь?</h4>
        <a href='https://t.me/dipix_support' target="blank">FAQ</a>
        <NavLink to={`/rules/subscription`}>возврат</NavLink>
        <NavLink to={`/rules/subscription`}>оплата</NavLink>
        <a href="https://t.me/dipix_support" target="blank">поддержка</a>
        <a href="https://t.me/pavlotarasenko" target="blank">дизайн</a>
      </div>
      <div className="social-links">
        <h4>Наши соцсети</h4>
        <a href="https://discord.gg/7w3t5VhKHS" target="blank" data-text-initial="Discord"><Icon icon="ic:outline-discord"/></a>
        <a href="https://t.me/dipix_empire" target="blank" data-text-initial="Telegram"><Icon icon="icon-park-outline:telegram"/></a>
        <a href="https://www.tiktok.com/@www.dipix.pw?_t=8eD05bZD06g&_r=1" target="blank" data-text-initial="Tik Tok"><Icon icon="icon-park-outline:tiktok"/></a>
        <a href="https://instagram.com/dipix_empire?igshid=YmMyMTA2M2Y=" target="blank" data-text-initial="Instagram"><Icon icon="mdi:instagram"/></a>
        <a href="https://www.youtube.com/@dipix.empire" target="blank" data-text-initial="Youtube"><Icon icon="icon-park-outline:youtube"/></a>
        <a href="https://github.com/dipix-empire" target="blank" data-text-initial="Github"><Icon icon="icon-park-outline:github-one"/></a>
      </div>
    </footer>
  )
}
