import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export default function Subscription ({ title }) {
  return [
    <div className="page subscription">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="page-content">
        <h1>На данный момент оформление подписки не работает через сайт. Вся информация находится в разделе <NavLink to={`/rules/subscription`}>Подписка</NavLink> в правилах. Подписка оформляется <a href="https://discord.gg/7w3t5VhKHS" target="blank">на сервере в Discord</a>. Подробнее в разделе <a href="https://t.me/dipix_support/14" target="blank">FAQ</a>.</h1>
      </div>
    </div>
  ]
}