import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useAuth } from '../../scripts/hook/useAuth.ts';

function Navbar() {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Здесь можно настроить ширину, считающуюся мобильной
    };

    checkIsMobile();

    window.addEventListener('resize', checkIsMobile);

    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  const handleLogClick = () => {
    if (user) {
      signOut(() => navigate('/', { replace: true }));
    } else {
      navigate('/me');
    }
  };

  return (
    <header className="scroll-header">
      <div className="navbar navbar-content">
        <h2 className="logo"><span className="di">DI</span><span className="pix">PIX</span></h2>
        <input className="checkbox" type="checkbox" name="" id="" />
        <div className="hamburger-lines">
          <span className="line line1"></span>
          <span className="line line2"></span>
          <span className="line line3"></span>
        </div>
        <nav className="navbar-items menu-items">
          <li><NavLink to={`/`} id="Главная">Главная</NavLink></li>
          <li><NavLink to={`/rules`} id="Правила">Правила</NavLink></li>
          <li><NavLink to={`/about`} id="О сервере">О сервере</NavLink></li>
          <li><a href="https://map.dipix.pw/#minecraft_overworld;flat;581,64,-616;3" target="blank" className="Карта">Карта</a></li>
          <li><NavLink to={`/subscription`} id="Подписка">Подписка</NavLink></li>
        </nav>
        <div className="log-content">
          {user && (
            <img src={`https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png`} alt='' />
          )}
          <a href={navigate} onClick={handleLogClick}>
            {isMobile ? (
              user ? <Icon icon="tdesign:logout" /> : <Icon icon="tdesign:login" />
            ) : (
              user ? "Выйти" : "Войти"
            )}
          </a>
        </div>
      </div>
    </header>
  );
}

export default Navbar;

