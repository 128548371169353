import React, { useState, useEffect } from "react";
import adminPath from "../../../storage/rulesMD/Chapters/7.Administration.md";
import ReactMarkdown from 'react-markdown';
import { Helmet } from "react-helmet-async";

export default function Administration ({ title }) {
  const [administration, setAdministration] = useState("")
  useEffect(() => {
    fetch(adminPath).then(res => res.text()).then(txt => { setAdministration(txt) })
  })
  return (
    <div id="administration" className="scroll-target">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ReactMarkdown>{administration || "Loading..."}</ReactMarkdown>
    </div>
  )
}