//import React, { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Routes, Route, Navigate } from "react-router-dom";
//import { scroller } from "react-scroll";
import Sidebar from "./Sidebar";
import Basis from "./Basis";
import Roles from "./Roles";
import Spawn from "./Spawn";
import Policy from "./Policy";
import Wars from "./Wars";
import Civil from "./Civil";
import Administration from "./Administration";
import Subscription from "./Subscription";

export default function Rules ({ title }) {
  return (
    <div className="rules">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="rules-container">
        <Sidebar/>
        <section>
          <HelmetProvider>
            <Routes>
              <Route path="/" element={<Navigate to="basis" />}/>
              <Route path="basis" element={<Basis title="Глава 1: Основные принципы сообщества."/>}/>
              <Route path="roles" element={<Roles title="Глава 2: Роли."/>}/>
              <Route path="spawn" element={<Spawn title="Глава 3: Спавн."/>}/>
              <Route path="policy" element={<Policy title="Глава 4: Политика."/>}/>
              <Route path="wars" element={<Wars title="Глава 5: Войны."/>}/>
              <Route path="civil" element={<Civil title="Глава 6: Гражданские войны."/>}/>
              <Route path="administration" element={<Administration title="Глава 7: Администрация."/>}/>
              <Route path="subscription" element={<Subscription title="Глава 8: Подписка."/>}/>
            </Routes>
          </HelmetProvider>
        </section>
      </div>
    </div>
  )
}